globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"umYshkHpZH2WIPv1HK2Tr"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from "@sentry/nextjs";

export const init = ({
  dsn,
  tunnel,
  release,
  // Without this, a local prod build would have no explicit environment and
  // use the prod environment. We can assume if there's no environment set,
  // it's going to be some kind of local/CI env. We don't want to pollute
  // development since this is a catch-all of developer machines and CI runs,
  // so we isolate these clients to the preview env.
  environment = "preview",
  enableReplayIntegration,
  enableCaptureConsoleIntegration,
  enableHttpClientIntegration,
}) =>
  Sentry.init({
    dsn,
    tunnel,
    integrations: [
      // The only replays being captured in the preview env are from our e2e's,
      // or local development on prod builds. We still want to keep sentry
      // enabled for logging, but replays are overkill that pollute the list of
      // actual replays and eat up our quota.
      enableReplayIntegration &&
        environment !== "preview" &&
        Sentry.replayIntegration({
          mask: [
            "#cardNumber",
            "#cardSecurityCode",
            "#cardExpireDateMonth",
            "#cardExpireDateYear",
          ],
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
        }),
      enableCaptureConsoleIntegration &&
        Sentry.captureConsoleIntegration({
          levels: ["warn", "error"],
        }),
      enableHttpClientIntegration &&
        Sentry.httpClientIntegration({
          failedRequestStatusCodes: [[500, 599]],
          failedRequestTargets: [/api.*.paymentlabs.io/],
        }),
    ].filter(Boolean),
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    release,
    environment,
    // Depth of objects attached in log metadata before they're listed as
    // [Object]. Default is 3.
    // https://docs.sentry.io/platforms/node/configuration/options/#normalize-depth
    normalizeDepth: 5,
  });
